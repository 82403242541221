import Vue from 'vue'
// @ts-ignore
import NoSsr from 'vue-no-ssr'
import { mapActions, mapGetters, mapState } from 'vuex'
import Button from '@/models-ts/ui/Button'
import ErrorMatcher from '@/utils/ErrorMatcher'
import VacancyApplicationItem from '@/models-ts/vacancies/VacancyApplicationItem'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import responseMixin from '@/mixins/responseMixin'
import rolebleMixin from '@/mixins/rolebleMixin'
import zendeskMixin from '@/mixins/zendeskMixin'
import { Roles } from '@/constants/user/roles'
import { formatUsd } from '@/utils/moneyFormat'
import { PositionType, POSITION_TYPES } from '@/constants/vacancies/positionTypes'
import { Stages, Statuses } from '@/constants/vacancies/statuses'
import { VACANCIES_MY, VACANCY_ADD, VACANCY_EDIT, VACANCY_EXTERNAL_APPLY } from '@/constants/routes'
import { VacancyModerationStages } from '@/constants/vacancies/vacancyModerationStages'
import { VacancyApplicationFormData } from '@/modals/ApplyForFullTimeJob/ApplyForFullTimeJob'
import { declineApplicationAsFreelancer, sendApplication } from '@/api/vacancies/applications'
import { deleteVacancy } from '@/api/vacancies'
import { ModerationStages } from '@/constants/backend/ModerationStages'
import { SalaryTypes } from '@/constants/vacancies/SalaryTypes'
import { RootState } from '@/store'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, rolebleMixin, zendeskMixin],
  components: { NoSsr },
  data () {
    return {
      publishing: false,
      published: false,
      deleting: false,
      saving: false,
      VACANCY_ADD,
      VACANCY_EDIT,
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
      userId: (state: any) => state.user?.id,
    }),
    ...mapGetters({
      vacancy: 'vacancyDetails/vacancy',
      isOwner: 'vacancyDetails/isOwner',
    }),
    isRangeSalary () {
      return this.vacancy.salary_type === SalaryTypes.RANGE
    },
    archivedOrCompletedVacancy () {
      return this.vacancy.stage === Stages.ARCHIVED || this.vacancy.stage === Stages.COMPLETED
    },
    archivedVacancy () {
      return this.vacancy.stage === Stages.ARCHIVED
    },
    isExternal () {
      return this.vacancy.external_url && (!this.vacancy.applicationForm?.length || !this.vacancy.applicationFormEnabled)
    },
    externalRoute () {
      if (this.isExternal && this.isLoggedIn) {
        return { name: VACANCY_EXTERNAL_APPLY, params: { id: this.vacancy.id, slug: this.vacancy.slug } }
      }
    },
    failedModeration () {
      return this.vacancy.moderation_stage === ModerationStages.FAILED
    },
    isCustomerParticipant () {
      return this.isCustomer && this.isOwner
    },
    isNewVacancy () {
      return this.vacancy.stage === Stages.NEW
    },
    isPublished () {
      return this.vacancy.status === Statuses.PUBLISHED
    },
    application () {
      return this.vacancy.applications.find((app: VacancyApplicationItem) =>
        app.freelancer.id === this.userId && app.isActive)
    },
    hasFreelancerActions () {
      return this.isNewVacancy && (!this.isLoggedIn || (this.isFreelancer && !this.isOwner))
    },
    hasApplyBtn () {
      return !this.archivedOrCompletedVacancy && (!this.isLoggedIn || (this.hasFreelancerActions && !this.application))
    },
    hasCancelAppBtn () {
      return this.hasFreelancerActions && this.application
    },
    hasEditBtn () {
      return this.isCustomerParticipant && this.isNewVacancy
    },
    hasPublishBtn () {
      return this.isCustomerParticipant && this.vacancy.stage !== Stages.COMPLETED && !this.archivedOrCompletedVacancy
    },
    hasDeleteBtn () {
      return this.isCustomerParticipant
    },
    hasRemoveBookmarkBtn () {
      return this.isFreelancer && this.vacancy.bookmarkId
    },
    hasSaveBookmarkBtn () {
      return this.isLoggedIn && !this.isOwner
    },
    hasPostBtn () {
      return !this.isLoggedIn || (this.isCustomer && !this.isOwner)
    },
    hasMenu () {
      return this.hasPublishBtn ||
        this.hasDeleteBtn ||
        this.hasRemoveBookmarkBtn ||
        this.hasSaveBookmarkBtn
    },
    positionType () {
      return POSITION_TYPES[this.vacancy.position_type as PositionType]?.name
    },
    remoteInfo () {
      return this.vacancy.remoteInfo
    },
  },
  created () {
    this.published = this.vacancy.status === Statuses.PUBLISHED
  },
  methods: {
    formatUsd,
    ...mapActions({
      openModal: 'ui/openModal',
      publishVacancy: 'vacancyDetails/publishVacancy',
      unpublishVacancy: 'vacancyDetails/unpublishVacancy',
    }),
    onApplyClick () {
      googleAnalyticsV2.send({
        event: this.vacancy.external_url
          ? 'ft-ext-job-button-apply-click'
          : 'ft-job-button-apply-click',
        ftj_id: this.vacancy.id,
      })
      if (!this.isExternal) {
        this.openModal({
          component: 'lx-lazy-modal',
          props: {
            factory: import(/* webpackChunkName: "job-modals" */ '@/modals/ApplyForFullTimeJob/ApplyForFullTimeJob.vue'),
            title: 'Apply For This Job',
            props: {
              vacancy: this.vacancy,
              onSuccess: async (application: VacancyApplicationFormData) => {
                googleAnalyticsV2.send({
                  event: 'ftj-application-sent',
                  ftj_id: this.vacancy.id,
                })
                if (this.isLoggedIn) {
                  await notifiableRequest({
                    request: async () => sendApplication({
                      vacancyId: this.vacancy.id,
                      ...application,
                    }),
                    title: 'Apply for the Job',
                    successText: 'Your application has been submitted.',
                    failureText: (err) => ErrorMatcher.isConflict(err)
                      ? 'You have already applied for this Job.'
                      : 'Error submitting application. Please try again.',
                  })
                  this.$router.push({ name: VACANCIES_MY })
                } else {
                  this.openModal({
                    component: 'lx-sign-up-modal',
                    props: {
                      predefinedRole: Roles.FREELANCER,
                      fromType: 'anotherFullTimeJob',
                      meta: {
                        vacancy_application: {
                          vacancyId: this.vacancy.id,
                          ...application,
                        }
                      }
                    }
                  })
                }
              }
            }
          }
        })
      } else if (!this.isLoggedIn) {
        this.openModal({
          component: 'lx-sign-up-modal',
          props: {
            predefinedRole: Roles.FREELANCER,
            fromType: 'anotherFullTimeJob',
            meta: {
              redirect_url: process.env.VUE_APP_FRONTEND_URL + this.$route.path
            }
          }
        })
      }
    },
    onCancelClick () {
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Cancel application?',
          text: `This application will be removed from Customer's applications list.`,
          buttons: [
            new Button({
              text: 'Cancel',
              classes: 'lx-transparent-blue-btn',
            }),
            new Button({
              text: 'Proceed',
              classes: 'lx-blue-btn',
              onClick: async () => {
                try {
                  await declineApplicationAsFreelancer(this.application.id)
                  this.$router.push({ name: VACANCIES_MY })
                } catch (e) {
                  this.parseError(e)
                }
              },
            }),
          ]
        }
      })
    },
    async onPublishClick () {
      try {
        this.publishing = true
        if (this.isPublished) {
          this.openModal({
            component: 'lx-composite-modal-new',
            props: {
              title: 'Are you sure you want to unpublish this Full-time Job?',
              text: `Once a Full-time Job has been unpublished, all talent applications wil be automatically declined.`,
              buttons: [
                new Button({
                  text: 'Cancel',
                  classes: 'lx-transparent-blue-btn',
                }),
                new Button({
                  text: 'Unpublish',
                  classes: 'lx-blue-btn',
                  onClick: async () => {
                    try {
                      this.publishing = true
                      await notifiableRequest({
                        request: () => this.unpublishVacancy(this.vacancy.id),
                        title: 'Unpublish job',
                        successText: `You have successfully unpublished your Full-time Job.`,
                        failureText: 'Error unpublishing Full-time Job. Please try again.'
                      })
                      googleAnalyticsV2.send({
                        event: 'ftj-drafted',
                        ftj_id: this.vacancy.id,
                      })
                    } finally {
                      this.publishing = false
                    }
                  },
                }),
              ]
            }
          })
        } else {
          await notifiableRequest({
            request: () => this.publishVacancy(this.vacancy.id),
            successText: (moderation_stage: VacancyModerationStages) => moderation_stage === VacancyModerationStages.MANUAL
              ? 'Your Job was successfully submitted for moderation. We will notify you of the outcome shortly.'
              : `Job: ${this.vacancy.name} was successfully published`,
            failureText: 'Error publishing Full-time Job. Please try again.'
          })
          googleAnalyticsV2.send({
            event: 'ftj-published',
            ftj_id: this.vacancy.id,
          })
        }
      } finally {
        this.publishing = false
      }
    },
    async onDeleteClick () {
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Delete Full-time Job',
          text: `Are you sure you want to remove <b>${this.vacancy.name}</b>? This Full-time Job will be permanently deleted.`,
          buttons: [
            new Button({
              text: 'Cancel',
              classes: 'lx-transparent-blue-btn',
            }),
            new Button({
              text: 'Delete',
              classes: 'lx-blue-btn',
              onClick: async () => {
                try {
                  this.deleting = true
                  await notifiableRequest({
                    request: async () => {
                      await deleteVacancy(this.vacancy.id)
                      this.$router.push({ name: VACANCIES_MY })
                    },
                    title: 'Delete Full-time Job',
                    successText: `Job: ${this.vacancy.name} has been deleted`,
                    failureText: 'Error deleting Full-time Job. Please try again.',
                  })
                } finally {
                  this.deleting = false
                }
              },
            }),
          ]
        }
      })
    },
    onClickSupport () {
      if (!this.isZendeskLoading) {
        this.openZendesk('My account')
      }
    },
    onArchiveClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/ArchiveVacancy/ArchiveVacancy.vue'),
          title: 'Archive This Job',
          props: {
            vacancy: this.vacancy,
          }
        }
      })
    },
  }
})
