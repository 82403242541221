import Vue from 'vue'
import { BookmarkTypes } from '@/constants/bookmarks/bookmarkType'
import BookmarkMenuItem from '@/partials/BookmarkButtons/BookmarkMenuItem/BookmarkMenuItem.vue'
import actionsMixin from '../actionsMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [actionsMixin],
  components: { BookmarkMenuItem },
  data () {
    return {
      bookmarkType: BookmarkTypes.VACANCY,
    }
  },
})
