import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { CUSTOMER_PROFILE } from '@/constants/routes'
import { CustomerTypes } from '@/constants/user/roles'
import UserVerificationBadge from '@/partials/StatusBadges/UserVerificationBadge/UserVerificationBadge.vue'

export default Vue.extend<any, any, any, any>({
  components: { UserVerificationBadge },
  data () {
    return {
      CUSTOMER_PROFILE,
    }
  },
  computed: {
    ...mapState('countries', {
      countriesIsLoaded: (state: any) => state.list.isLoaded,
      countriesIsLoading: (state: any) => state.list.isLoading,
      countries: (state: any) => state.list.value,
    }),
    ...mapGetters({
      vacancy: 'vacancyDetails/vacancy',
    }),
    customer () {
      return this.vacancy.relations.Customer
    },
    isCustomerVerified () {
      return this.customer?.is_customer_verified
    },
    customerType () {
      return this.customer?.customerType
    },
    isCompany () {
      return this.customer.customerType === CustomerTypes.COMPANY
    },
    isPerson () {
      return this.customer.customerType === CustomerTypes.INDIVIDUAL
    },
    website () {
      const website = this.customer.website
      if (website) {
        try {
          const url = new URL(website)
          return { link: website, domain: url.hostname }
        } catch (e) {
          return { link: website, domain: website }
        }
      }
    },
    locations () {
      if (this.isPerson) {
        if (!this.countriesIsLoaded) {
          return null
        }
        const country = (this.countries || []).find((c: any) => c.id === this.customer.internalCountryId)
        return country?.name
      }
      return this.customer.countries.length > 0
        ? this.customer.countries.map((c: any) => c.name).join(', ')
        : null
    },
  },
})
