import { PositionType, POSITION_TYPES } from '@/constants/vacancies/positionTypes'
import { Statuses } from '@/constants/vacancies/statuses'
import { DATE_TIME_FORMAT } from '@/constants/utils/date'
import { BROWSE_VACANCIES_BY_SKILL, CUSTOMER_PROFILE } from '@/constants/routes'
import { formatDate, getHumanDate } from '@/utils/date'
import { formatUsd } from '@/utils/moneyFormat'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { formatDescription } from '@/utils-ts/strings'
import Skill from '@/models-ts/Skill'
import Vacancy from '@/models-ts/vacancies/Vacancy'
import { ExperienceExplanation } from '@/constants/vacancies/experiences'
import { SalaryTypes } from '@/constants/vacancies/SalaryTypes'
import VacancyLocationTooltip from '@/partials/VacancyLocationTooltip/VacancyLocationTooltip.vue'
import UserVerificationBadge from '@/partials/StatusBadges/UserVerificationBadge/UserVerificationBadge.vue'

export default Vue.extend<any, any, any, any>({
  components: {
    VacancyLocationTooltip,
    UserVerificationBadge,
  },
  data () {
    return {
      CUSTOMER_PROFILE,
      mobileMoreSkillsOpened: false,
    }
  },
  computed: {
    ...mapGetters({
      vacancy: 'vacancyDetails/vacancy',
    }),
    customer () {
      return this.vacancy.relations.Customer
    },
    isPublished () {
      return this.vacancy.status === Statuses.PUBLISHED
    },
    publishDate () {
      const date = this.isPublished ? this.vacancy.first_published_at : this.vacancy.created_at
      return {
        local: getHumanDate(date),
        utc: formatDate(date, DATE_TIME_FORMAT),
      }
    },
    positionType () {
      return POSITION_TYPES[this.vacancy.position_type as PositionType]?.name
    },
    remoteInfo () {
      return this.vacancy.remoteInfo
    },
    isRangeSalary () {
      return this.vacancy.salary_type === SalaryTypes.RANGE
    },
    experience () {
      const vacancy = this.vacancy as Vacancy
      if (vacancy.work_experience) {
        return ExperienceExplanation[vacancy.work_experience]
      }
    },
    description () {
      return formatDescription(this.vacancy.description)
    },
    skills () {
      const category = this.vacancy.category
      const skills = this.vacancy.relations.Skill
        .filter((skill: any) => skill.id !== category?.id)
      return [category, ...skills]
        .filter(Boolean)
        .map((skill: Skill) => ({
          text: skill.name,
          link: {
            name: BROWSE_VACANCIES_BY_SKILL,
            params: { skill: skill.url }
          }
        }))
    },
  },
  methods: {
    formatUsd,
  }
})
